import Vue from 'vue';
import Router from 'vue-router';
import HomePage from './components/HomePage.vue';
import EventsPage from './components/EventsPage.vue';
import BingoPage from './components/BingoPage.vue';
import InfoPage from './components/InfoPage.vue';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomePage,
    },
    {
      path: '/events',
      name: 'Events',
      component: EventsPage,
    },
    {
      path: '/bingo',
      name: 'Bingo',
      component: BingoPage,
    },
    {
      path: '/info',
      name: 'Info',
      component: InfoPage,
    }
  ],
});
