<template>
    <div class="container">
        <div class="wrapper">
            <div class="main">
                <div style="
                    position:fixed;
                    top:30vh;
                    left:50vw;
                    transform:translate(-50%, -50%);
                    padding:16px;
                    width:100%;
                    max-width:500px;
                    box-sizing:border-box;
                ">
                    <h1 style="text-align: center; margin:16px 0px;">
                        That's a wrap!
                    </h1>
                    <p style="text-align: center; margin:4px 0px;">Thanks for joining us at IMTS 2024. See you in 2026!</p>
                    <p style="text-align: center; margin:8px 0px;"><img @click="goToUrl('https://chatter.dev/')" src='/img/PoweredByChatter-100.png' style='width:180px; margin:16px 0px 4px 0px; text-align: center;'/></p>
                    
                </div>
            </div>
            <div class="main" id="capture" v-if="false">
                <div class="header">
                    <div>
                        <h1>IMTS Community Events</h1>
                    </div>
                    <div>
                        <a href="https://calendar.google.com/calendar/embed?src=c_b1f62881018817eee5d5f1cfb7fba7028be28c3e4f0ac895dfc0fb15eff6b631%40group.calendar.google.com&ctz=America%2FChicago" target="_blank">Google Calendar</a>
                        <a href="https://f6idcwsxdyk.typeform.com/to/K7yXXUDJ" target="_blank">Submit an Event</a>
                        <p>All times in CT</p>
                    </div>
                </div>
                <div class="loader" v-if="!jsonData">
                    <FontAwesomeIcon icon="circle-notch" spin />
                </div>
                <div v-for="(day, index) in eventsByDay" :key="index" style="display:flex;flex-direction: column; gap:8px; margin:8px 0px;">
                    <p>{{ day.name }}</p>
                    <EventCard v-for="(event, index) in day.events" :key="index" :event="event" />
                </div>
                <div v-if="jsonData" style="display:flex;flex-direction: column; gap:8px; margin:8px 0px;">
                    <p>Week-Long Events</p>
                    <EventCard v-for="(event, index) in weekLongEvents" :key="index" :event="event" />
                </div>
            </div>
            <div class="footer" v-if="jsonData && false"><img @click="goToUrl('https://chatter.dev/')" src='/img/PoweredByChatter-100.png' /></div>
        </div>
    </div>
</template>
  
<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

library.add(faCircleNotch)

import EventCard from './EventCard.vue';
import html2canvas from 'html2canvas';
    export default {
        name: 'HomePage',
        components: {
            EventCard,
            FontAwesomeIcon,
        },
        data() {
            return {
                jsonData: null,
                weekLongEvents: [
                    {
                        "name": "Toolpath Cornhole Tournament & Machinist's Lounge",
                        "start": "2024-09-09T14:00:00-05:00",
                        "description": "Don't miss the opportunity to hang out with the Toolpath crew and our awesome partners, make new friends, and earn some bragging rights by entering the first ever #instamachinist Cornhole Tournament hosted by Toolpath and made possible from our friends at 5th-Axis, Saunders Machine Works, Pierson Workholding, DSI - Design & Software International, DATRON Dynamics, and Air Turbine Tools. Each day, four teams compete each hour. At the end of the day the hourly winners compete to determine the overall daily winner. Thanks to —we have some great prizes lined up!",
                        "location": "Toolpath Labs Booth #338428",
                        "eventLink": "https://info.toolpath.com/instamachinist-cornhole-tournament-imts-2024",
                        "infoLink": "https://info.toolpath.com/instamachinist-cornhole-tournament-imts-2024",
                        "image": "https://info.toolpath.com/hubfs/3%20-%20Landing%20pages/IMTS%20and%20Cornhole%20pages/Cornhole_boards_2024-Aug-07_05-07-30AM-000_CustomizedView14134781712_jpg.jpg"
                    },
                ],
             };
        },
        created() {
            fetch('https://api2.chatter.dev/imts/calendar')
                .then(response => response.json())
                .then(data => {
                this.jsonData = data.data;
                })
                .catch(error => {
                console.error('Error fetching data:', error);
                });
        },
        methods: {
            toTimeString(datetimeString) {
                const date = new Date(datetimeString);
                const options = {
                    timeZone: 'America/Chicago',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                };
                return date.toLocaleString('en-US', options);
            },
            goToUrl(url) {
                window.open(url, '_blank');
            },
        async shareToInstagram() {
            const divElement = document.getElementById('capture'); // Replace 'capture' with your div's ID
            const title = 'events';

            try {
                // Capture the div content as a canvas
                const canvas = await html2canvas(divElement);

                // Convert the canvas to a blob
                canvas.toBlob(async (blob) => {
                    if (!blob) {
                        console.error('Failed to convert canvas to blob');
                        return;
                    }

                    // Create a File object from the blob
                    const filesArray = [
                        new File([blob], `${title}.png`, {
                            type: 'image/png',
                            lastModified: new Date().getTime(),
                        }),
                    ];

                    // Prepare the share data
                    const shareData = {
                        title: this.title,
                        files: filesArray,
                    };

                    // Check if the navigator can share this data
                    if (navigator.canShare && navigator.canShare(shareData)) {
                        // Use the native share functionality
                        await navigator.share(shareData);
                    } else {
                        console.error('Sharing not supported or share data is invalid.');
                        alert('Sharing not supported or share data is invalid.');
                    }
                }, 'image/png');
            } catch (error) {
                console.error('Error capturing or sharing the div content:', error);
                alert('Error capturing or sharing the div content.');
            }
        },
        },
        computed: {
            eventsByDay() {
                if (!this.jsonData) return [];
                const eventsByDay = {};
                this.jsonData.forEach(event => {
                const date = new Date(event.start);
                const day = date.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' });
                if (!eventsByDay[day]) {
                    eventsByDay[day] = [];
                }
                eventsByDay[day].push({
                    name: event.name,
                    start: event.start,
                    location: event.location,
                    eventLink: event.eventLink,
                    infoLink: event.infoLink,
                    description: event.description,
                    image: event.image,
                });
                });
                // Sort events by start time
                Object.values(eventsByDay).forEach(events => {
                    events.sort((a, b) => new Date(a.start) - new Date(b.start));
                });

                return Object.entries(eventsByDay).map(([name, events]) => ({ name, events }));
            }
        }
    };
</script>
  
<style scoped>
  /* Add any styles if needed */
</style>
  