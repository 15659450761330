<template>
    <div class="bingo-container">
        <transition name="slidefast">
            <complete-message v-if='complete' @reset="reset" @share="shareToInstagram" @close="completeMessageOverride = false"></complete-message>
        </transition>
        <div class='bingo-grid'>
            <bingo-card v-for='c, i in cards' :cd='c' :key='i' @check='check(i)'></bingo-card>
        </div>
        <div class='lower-text'>
            <p v-if='token'></p>
            <p v-else>Error: No Token Issued</p>
            <p class='count'>{{noCompletes}}/25</p>
        </div>
        <!-- <div class='lower-brand'>
            <a href='https://chatter.dev/'>
                <img src='./img/PoweredByChatter-100.png' />
            </a>
        </div> -->
        <shirt-form v-if='showForm'></shirt-form>
    </div>
</template>
  
<script>
import BingoCard from './BingoCard.vue'
import CompleteMessage from './CompleteMessage.vue'
import html2canvas from 'html2canvas';
    export default {
        name: 'BingoInterface',
        components: {
            BingoCard,
            CompleteMessage,
        },
        data() {
            return {
                cards: null,
                token: '',
                showForm: false,
                completeMessageOverride: false,
                items: [
                    'IOT',
                    'AI Powered',
                    'Cutting Air',
                    '"Your Partner"',
                    'Free Beer',
                    'No Photos Please',
                    'Distibutor Sales Only',
                    '"Revolutionary" (It\'s not)',
                    'Hybrid Additive',
                    '"Can I scan your badge?"',
                    'Booth Girls',
                    'Clueless Salesperson',
                    'Find a Youtuber',
                    'Bottle Opener',
                    '"Aerospace Grade"',
                    '"Apps!"',
                    'Conversational in 2024',
                    'Super ugly machine',
                    '10+ Axis machine',
                    'Cutting AL fast as a flex',
                    '"Tenths all day long"',
                    'Chip management problems',
                    'Cracked window',
                    '"Is this your first time?"',
                    'Salesperson shitting on competitor',
                    'Actually useful swag',
                    'Find Tap-Kun',
                    'Visibly drunk salesperson',
                    'Too much VC funding',
                ]
            }
        },
        mounted() {
            if (!localStorage.getItem('cards') || !localStorage.getItem('token')) {
                console.log('No cards found, resetting')
                this.reset()
            } else {
                console.log('cards', localStorage.getItem('cards'))
                console.log('token', localStorage.getItem('token'))
                this.cards = JSON.parse(localStorage.getItem('cards'))
                this.token = localStorage.getItem('token')
            }
        },
        methods: {
            shuffle: function (array) {
                let currentIndex = array.length,  randomIndex;
            
                // While there remain elements to shuffle.
                while (currentIndex != 0) {
            
                // Pick a remaining element.
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;
            
                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                    array[randomIndex], array[currentIndex]];
                }
            
                return array.slice(0,24);
            },
            check(i) {
                if (!this.cards[i].free) {
                    this.cards[i]['checked'] = !this.cards[i]['checked']
                }
            },
            async reset() {
                const ret = []
                for (const item of this.shuffle(this.items)) {
                    ret.push({'text':item, 'checked':false})
                }
                ret.splice(12,0,{'text':'Free!','checked':true,'free':true})
                this.getToken()
                localStorage.setItem('cards', JSON.stringify(ret))
                this.cards = ret
            },
            getToken() {
                const token = this.generateRandomToken()
                this.token = token;
                localStorage.setItem('token', token)
            },
            generateRandomToken() {
                const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let result = '';
                for (let i = 0; i < 28; i++) {
                    result += characters.charAt(Math.floor(Math.random() * characters.length));
                }
                return result;
            },
            async shareToInstagram() {
                const divElement = document.getElementById('app');
                const title = 'bingo';

                const canvas = await html2canvas(divElement, {
                    backgroundColor: '#202020',
                    scale: 2,
                });

                // Convert to a blob
                canvas.toBlob(async (blob) => {
                    if (!blob) {
                        console.error('Canvas to blob failed');
                        return;
                    }
                    // const filesArray = [
                    //     new File([blob], `${title}.png`, {
                    //         type: 'image/png',
                    //         lastModified: new Date().getTime(),
                    //     }),
                    // ];

                    // download the image
                    const a = document.createElement('a');
                    a.href = URL.createObjectURL(blob);
                    a.download = `${title}.png`;
                    a.click();
                });
                
                // const igDeepLink = `instagram://story-camera?background_media=${encodeURIComponent(imgUrl)}`;
                // window.open(igDeepLink, '_blank');

                // // Fetch the image and convert it to a blob
                // const response = await fetch(imgUrl);
                // const blobImageAsset = await response.blob();

                // // Create a File object from the blob
                // const filesArray = [
                //     new File([blobImageAsset], `${title}.png`, {
                //         type: 'image/png',
                //         lastModified: new Date().getTime(),
                //     }),
                // ];

                // // Prepare the share data
                // const shareData = {
                // title: this.title,
                // files: filesArray,
                // };

                // // Check if the navigator can share this data
                // if (navigator.canShare && navigator.canShare(shareData)) {
                // // Use the native share functionality
                // await navigator.share(shareData);
                // } else {
                //     console.error('Sharing not supported or share data is invalid.');
                //     alert('Sharing not supported or share data is invalid.');
                // }
            },
        },
        computed: {
            noItems: function() {
                return this.items.length
            },
            complete: function() {
                if (!this.cards) {
                    return false
                }
                const completes = []
                this.cards.forEach(function (c, i) {
                    if (c['checked']) {
                        completes.push(i)
                    }
                })
                const solutions = [
                    // horizontal
                    [0,1,2,3,4],
                    [5,6,7,8,9],
                    [10,11,12,13,14],
                    [15,16,17,18,19],
                    [20,21,22,23,24],
                    // vertical
                    [0,5,10,15,20],
                    [1,6,11,16,21],
                    [2,7,12,17,22],
                    [3,8,13,18,23],
                    [4,9,14,19,24],
                    // diagonal
                    [0,6,12,18,24],
                    [4,8,12,16,20]
                ]
                let checker = (arr, target) => target.every(v => arr.includes(v))
                console.log(completes)
                for (const s of solutions) {
                    if (checker(completes,s)) {
                        return true
                    }
                }
                return false
            },
            noCompletes: function() {
                if (!this.cards) {
                    return -1
                }
                let ret = 0
                this.cards.forEach(function (c) {
                    if (c['checked']) {
                        ret++
                    }
                })
                return ret
            },
        },
        watch: {
            'cards': {
                handler: function () {
                    console.log('saving')
                    localStorage.setItem('cards', JSON.stringify(this.cards))
                },
                deep: true
            }
        }
    };
</script>
  
<style scoped>

.bingo-container {
    display:flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width:100%;
    max-width:600px;
}
    
.bingo-grid {
    width:100%;
    display:grid;
    grid-template-columns:repeat(5, 20%);
}

h1 {
    text-align: center;
}

.bingo-grid > div {
    border:1px solid #31302E;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align:center;
    border-radius:4px;
    margin:3px;
    background-color:var(--secondary);
    cursor:pointer;
    text-transform: capitalize;
    aspect-ratio: .8;
    padding: 2px 8px;
    font-size: 7.5pt;
    font-weight:600;
}

@media (min-width: 600px) {
    .bingo-grid > div {
        font-size: 10pt;
    }
}

.bingo-grid > div.checked {
    background-color:var(--green);
    text-shadow: 0px 0px 4px #0005;
}

.bingo-grid > div.free {
    font-size:11pt;
    font-weight:600;
}

.complete-message h1 {
    margin-top:0px;
    font-size: 13pt;
}

.complete-message p {
    font-size: 9pt;
}

.complete-message > div {
    width:100%;
    max-width:800px;
    border-radius:4px;
    margin: 20px auto;
    color: #fff;
    background-color: var(--secondary);
    padding: 10px;
    border: 1px solid #31302E;
    padding: 15px 0px;
}

.complete-message {
    text-align:center;
}

.complete-message i {
    margin:0px 7px;
}

.lower-text {
    display:flex;
    align-items: center;
    justify-content: space-between;
}
.lower-text p {
    color:var(--text-dark);
    font-family:'Roboto Mono';
    font-weight:500;
    font-size:10pt;
    padding:0px 12px;
}

.feed {
    max-width:100%;
    display:flex;
    flex-wrap:wrap;
}
.feed div img {
    max-width:100px;
}
.lower-brand {
    position: fixed;
    bottom: 0px;
    left: 50vw;
    transform: translateX(-50%);
    padding: 30px;
}
.lower-brand img {
    width: 170px;
    margin: 0px auto;
    display: block;
    opacity:.8;
}
.lower-brand img:hover {
    opacity: 1;
}
</style>
  