import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import VueGtag from 'vue-gtag';
import './styles.css'

Vue.config.productionTip = false

// Vue.use(VueGtag, {
//   id: 'G-ZV1BE16E7R',
//   router
// });

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
