<template>
    <div class="event-card">
        <div class="event-card-image" :style="{ backgroundImage: 'url(' + event.image + ')' }">
        </div>
        <div class="event-card-body">
            <div>
                <h2>{{ event.name }}</h2>
                <h3>{{ displayTime }}</h3>
                <h3>{{ event.location }}</h3>
            </div>
            <div>
                <p>{{ event.description }}</p>
            </div>
            <div class="lower-buttons">
                <button class="green" :class="{ disabled: !event.eventLink }" @click="eventLink">Add to Calendar</button>
                <button :class="{ disabled: !event.infoLink }" @click="infoLink">More Info</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EventCard',
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
    }),
    methods: {
        eventLink() {
            window.open(this.event.eventLink, '_blank');
        },
        infoLink() {
            window.open(this.event.infoLink, '_blank');
        },
        toTimeString(datetimeString) {
            const date = new Date(datetimeString);
            const options = {
                timeZone: 'America/Chicago',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            };
            return date.toLocaleString('en-US', options);
        },
        getWeekdayString(datetimeString) {
            const date = new Date(datetimeString);
            return date.toLocaleDateString('en-US', { weekday: 'long' });
        },
    },
    computed: {
        displayTime() {
            if (!this.event) return '';
            return this.getWeekdayString(this.event.start) + ' @ ' + this.toTimeString(this.event.start);
        }
    }
};
</script>

<style scoped>
.event-card {
    display:flex;
    flex-direction: column;
    background-color:#2b2b2b;
    border-radius:8px;
    overflow:hidden;
    margin-bottom:8px;
}
.event-card-image {
    height: 140px;
    background-color:#4b4b4b;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.event-card-body {
    display:flex;
    flex-direction: column;
    padding:8px 16px;
    gap:8px;
}
.event-card-body div {
    display:flex;
    flex-direction: column;
}
.event-card-body .lower-buttons {
    display:flex;
    gap:8px;
    flex-wrap:nowrap;
    flex-direction: row;
}
.lower-buttons button {
    flex-grow:1;
    flex-basis:0;
}
h2 {
    font-size: 24px;
}
h3 {
    font-size: 13px;
}
p {
    font-size: 13px;
}
button {
    border-radius:8px;
    background-color:#333333;
    color:var(--text);
    border:none;
    padding:10px;
    font-weight:500;
    letter-spacing:.2px;

    cursor:pointer;
}
button:hover {
    background-color:#3c3c3c;
}
button.green {
    background-color: #58AC6E;
}
button.green:hover {
    background-color: #65b67b;
}
button.disabled {
    background-color:#333;
    color:#555;
    pointer-events: none;
}
</style>