<template>
    <div @click="$emit('check')" :class='{ checked : cd.checked, free : cd.free }'>
        <p>{{cd.text}}</p>
    </div>
</template>

<script>
export default {
    name: 'BingoCard',
    props: {
        cd: {
            type: Object,
            required: true,
        },
    },
};
</script>