<template>
    <div class="container">
        <div class="wrapper">
            <div class="image-header">
                <div class="bg" style="background-image: url('/img/imts-floor.png');"></div>
                <div class="image-header-inner">
                    <h1>See Chatter at IMTS</h1>
                </div>
            </div>
            <div class="main">
                <div class="overlay">
                    <ContactInput />
                </div>
                <div class="map-container">
                    <div class="item-card">
                        <img src="/img/enshu-booth.png" alt="enshu-booth" />
                        <div class="item-card-body">
                            <div>
                                <h2>Enshu USA</h2>
                                <h3>Booth #339430 - South Building, Level 3</h3>
                            </div>
                            <p>Come see Chatter running on Enshu's legendary GE460H. Fun fact, the first machine ever connected to Chatter was an Enshu!</p>
                        </div>
                    </div>
                    <div class="item-card">
                        <img src="/img/ur-booth.png" alt="ur-booth" />
                        <div class="item-card-body">
                            <div>
                                <h2>Universal Robots</h2>
                                <h3>Booth #236131 - North Building, Level 3</h3>
                            </div>
                            <p>In collaboration with <a href='https://www.lightsoutmfg.com/' target="_blank">Lights Out MFG</a>, we'll have Chatter set up on the Haas UMC500 running parts fully automated.</p>
                        </div>
                    </div>
                    <div class="item-card" v-if="false">
                        <img src="/img/willemin-booth.png" alt="willemin-booth" />
                        <div class="item-card-body">
                            <div>
                                <h2>Willemin-Macodel</h2>
                                <h3>Booth #339341 - South Building, Level 3</h3>
                            </div>
                            <p>Chatter will be on display at the Willemen booth running demos on the 408-MT.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer"><img @click="goToUrl('https://chatter.dev/')" src='/img/PoweredByChatter-100.png' /></div>
        </div>
    </div>
</template>
  
<script>
import ContactInput from './ContactInput.vue';

    export default {
        name: 'InfoPage',
        components: {
            ContactInput,
        },
        data() {
            return {
             };
        },
        created() {
        },
        methods: {
        },
        computed: {
        }
    };
</script>
  
<style scoped>
.item-card {
    background-color:#2b2b2b;
    border-radius:8px;
    display:flex;
    flex-direction: column;
    padding: 0;
    margin:8px 0px;
    overflow:hidden;
}
.item-card-body {
    display:flex;
    flex-direction: column;
    padding:8px 16px;
}
.item-card img {
    height:auto;
    margin:0;
    width:100%;
    border-radius: 8px 8px 0px 0px;
}

h2 {
    font-size: 24px;
}
h3 {
    font-size: 13px;
}
p {
    font-size: 13px;
}

.map-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
</style>
  