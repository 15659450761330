<template>
  <div id="app">
    <nav-bar />
    <router-view />
  </div>
</template>

<script>

// import nav bar
import NavBar from './components/NavBar.vue'

export default {
    name: 'App',
    components: {
      NavBar,
    },
    data() {
      return {
        navExpanded: false,
      }
    },
    mounted() {
      document.title = 'Chatter at IMTS';
    },
}
</script>
