<template>
    <div class="container">
        <div class="wrapper">
            <div class="image-header">                
                <div class="bg" style="background-image: url('/img/tap-kun.png');"></div>
                <div class="image-header-inner">
                    <h1>IMTS Bingo</h1>
                </div>
            </div>
            <div class="main">
                <bingo-interface></bingo-interface>
            </div>
            <div class="footer"><img @click="goToUrl('https://chatter.dev/')" src='/img/PoweredByChatter-100.png' /></div>
        </div>
    </div>
</template>
  
<script>
import BingoInterface from './BingoInterface.vue';
    export default {
        name: 'BingoPage',
        components: {
            BingoInterface,
        },
        data() {
            return {
                jsonData: ''
             };
        },
        created() {
            fetch('https://api2.chatter.dev/imts/calendar')
                .then(response => response.json())
                .then(data => {
                this.jsonData = data.data;
                })
                .catch(error => {
                console.error('Error fetching data:', error);
                });
        },
        methods: {
            toTimeString(datetimeString) {
                const date = new Date(datetimeString);
                const options = {
                    timeZone: 'America/Chicago',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                };
                return date.toLocaleString('en-US', options);
            },
            goToUrl(url) {
                window.open(url, '_blank');
            },
        },
        computed: {
            eventsByDay() {
                if (!this.jsonData) return [];
                const eventsByDay = {};
                this.jsonData.forEach(event => {
                const date = new Date(event.start);
                const day = date.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' });
                if (!eventsByDay[day]) {
                    eventsByDay[day] = [];
                }
                eventsByDay[day].push({
                    name: event.name,
                    start: event.start,
                    location: event.location,
                    eventLink: event.eventLink,
                    infoLink: event.infoLink,
                    description: event.description,
                    image: event.image,
                });
                });
                // Sort events by start time
                Object.values(eventsByDay).forEach(events => {
                    events.sort((a, b) => new Date(a.start) - new Date(b.start));
                });

                return Object.entries(eventsByDay).map(([name, events]) => ({ name, events }));
            }
        }
    };
</script>
  
<style scoped>
  /* Add any styles if needed */
</style>
  