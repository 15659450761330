<template>
    <div class='complete-message'>
        <h2>Card Complete!🎉</h2>
        <button @click="$emit('share')">
            <font-awesome-icon :icon="['fab', 'instagram']" />
            Share on social media #IMTSBingo
        </button>
        <p>Keep going, or <a href="#" @click="$emit('reset')">start a new card</a>!</p>
    </div>
</template>

<script>
import Vue from 'vue'
import VueConfetti from 'vue-confetti'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

library.add(faInstagram)
Vue.use(VueConfetti)

export default {
    name: 'BingoCard',
    components: {
        FontAwesomeIcon,
    },
    props: {
        cd: {
            type: Object,
            required: true,
        },
    },
    mounted() {
        this.$confetti.start({
            particles: [
                {
                    'type': 'rect',
                }
            ]
        })
        setTimeout(() => {
            this.$confetti.stop()
        }, 2500)
    },
};
</script>

<style scoped>
.complete-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    margin-bottom: 8px;
    border-radius:8px;
    background-color:#31302e;
    padding: 14px 0px 20px 0px;
    width:100%;
}
button {
    background-color:#4a4a4a;
}
button:hover {
    background-color:#5a5a5a;
}
</style>