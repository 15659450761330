<template>
    <div class="contact-input">
        <h2>Let's chat!</h2>
        <p>Somebody can come over to show you Chatter right now, or we can schedule a meeting for a later time.</p>
        <transition name="slidefast">
            <button @click="expanded = !expanded" v-if="!expanded && !success" class="green">Call us over</button>
        </transition>
        <transition name="slidefast">
            <div class="success-message" v-if="success">
                <font-awesome-icon :icon="['fas', 'check-circle']" />
                <h3>Request recieved, we'll be with you shortly!</h3>
            </div>
        </transition>
        <transition name="slidefast">
            <div class="contact-input-form" v-if="expanded && !success">
                <div>
                    <label>Name<span class="required">*</span></label>
                    <input v-model="contact.name" />
                </div>
                <div>
                    <label>Company<span class="required">*</span></label>
                    <input v-model="contact.company" />
                </div>
                <div>
                    <label>Email<span class="required">*</span></label>
                    <input v-model="contact.email" />
                </div>
                <div>
                    <label>Mobile Phone</label>
                    <input v-model="contact.phone" />
                </div>
                <div>
                    <label>Booth / Location<span class="required">*</span></label>
                    <select v-model="contact.booth">
                        <option v-for="(booth, index) in boothOptions" :key="index" :value="booth">{{ booth }}</option>
                    </select>
                </div>
                <div v-if="contact.booth === 'Other'">
                    <input v-model="otherBoothValue" placeholder="Enter location" />
                </div>
                <div>
                    <label>Note</label>
                    <input v-model="contact.note" />
                </div>
                <div v-if="loading" style="margin: 14px 0px; color: var(--text-dark); font-size: 30px">
                    <FontAwesomeIcon icon="circle-notch" spin />
                </div>
                <div v-else style="margin-top: 16px; margin-bottom: 2px; gap:8px">
                    <button class="green" @click="submit(true)">Come meet me at the booth</button>
                    <button @click="submit(false)">Let's schedule a meeting</button>
                </div>
                <div v-for="(msg, index) in errorMessages" :key="index" style="color: var(--red); margin-top: 2px; font-size: 14px">{{ msg }}</div>
            </div>
        </transition>
        <vue-recaptcha
            ref="recaptcha"
            sitekey="6LfRtjcqAAAAAA6TRXCPpgwXgvZIa3gkvTpj8ufm"
            size="invisible"
            @verify="onCaptchaVerified"
            @expired="onCaptchaExpired"
        />
    </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
library.add(faCheckCircle);
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
library.add(faCircleNotch);

export default {
    name: 'ContactInput',
    components: {
        FontAwesomeIcon,
        'vue-recaptcha': VueRecaptcha,
    },
    data() {
        return {
            expanded: false,
            boothOptions: [
                'Enshu',
                'Universal Robots',
                // 'Willemin-Macodel',
                'Other',
            ],
            otherBoothValue: '',
            contact: {
                name: '',
                booth: 'Enshu',
                company: '',
                email: '',
                phone: '',
                note: '',
            },

            errorMessages: [],
            success: false,
            atBooth: false,

            loading: false,
        };
    },
    mounted() {
        // Extract the booth parameter from the URL hash
        const getHashParams = () => {
            const hash = window.location.hash;
            const indexOfQuestionMark = hash.indexOf('?');
            const params = new URLSearchParams(hash.substring(indexOfQuestionMark + 1));
            return params;
        };

        const urlParams = getHashParams();
        const booth = urlParams.get('booth');
        
        if (booth) {
            switch (booth) {
                case 'enshu':
                    this.contact.booth = 'Enshu';
                    break;
                case 'ur':
                    this.contact.booth = 'Universal Robots';
                    break;
                case 'wm':
                    this.contact.booth = 'Willemin-Macodel';
                    break;
                case 'other':
                    this.contact.booth = 'Other';
                    break;
            }
        }
    },
    methods: {
        submit(atBooth) {
            this.loading = true;
            this.success = false;
            this.errorMessages = [];
            this.atBooth = atBooth;

            this.$refs.recaptcha.execute();
        },
        onCaptchaVerified(recaptchaToken) {
            const requestData = {
                ...this.contact,
                meetAtBooth: this.atBooth,
                scheduleMeeting: !this.atBooth,
                recaptcha: recaptchaToken,
            };

            if (this.contact.booth === 'Other') {
                requestData.booth = this.otherBoothValue;
            }

            const endpoint = 'https://api2.chatter.dev/imts/contact';
            fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            }).then(response => {
                if (response.ok) {
                    this.success = true;
                    this.loading = false;
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            Object.keys(data.errors).forEach(key => {
                                data.errors[key].forEach(msg => {
                                    this.errorMessages.push(msg);
                                });
                            });
                        } else {
                            this.errorMessages = ['There was an error submitting your request. Please try again later.'];
                        }
                        this.loading = false;
                    });
                }
            }).catch(() => {
                this.errorMessages = ['There was an error submitting your request. Please try again later.'];
                this.loading = false;
            });
        },
        onCaptchaExpired() {
            this.$refs.recaptcha.reset(); // Reset the reCAPTCHA if it expires
        },
    },
};
</script>

<style scoped>
.contact-input {
    margin: 8px 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color:#2b2b2b;
    padding: 16px;
    width: 100%;
    border-radius: 8px;
    box-sizing: border-box;
}

.contact-input-form {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.contact-input-form > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.contact-input-form label {
    font-size: 11px;
    color: var(--text-dark);
}

.contact-input-form input, .contact-input-form select {
    padding: 8px;
    border-radius: 4px;
    background-color: #333;
    border:none;
    color: var(--text);
}

label > span.required {
    color: var(--red);
    margin-left:3px;
}

.success-message {
  display: flex;
  gap: 12px;
  align-items: center;
  background-color: #444;
  padding: 16px 16px;
  border-radius: 8px;
}

.success-message svg {
  height: 30px;
  color: var(--green);
}

.success-message h3 {
  font-weight: 600;
}


</style>