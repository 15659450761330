<template>
    <div class="navbar">
        <img @click="goToUrl('https://chatter.dev/')" src="icon-128.png" class="navbar-header" />
        <div :class="{ selected: $route.path === link.path }" v-for="link in links" :key="link.name" @click="handleLink(link)">
            <FontAwesomeIcon v-if="link.icon" :icon="link.icon" />
            <img v-else-if="link.img" :src="link.img" />
            <p>{{ link.name }}</p>
        </div>
    </div>
</template>

<script>

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faHome, faBorderAll, faCalendarAlt, faCog, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

library.add(faHome, faBorderAll, faCalendarAlt, faCog, faMapMarkerAlt)

export default {
    name: 'NavBar',
    components: {
        FontAwesomeIcon,
    },
    data() {
        return {
            links: [
                // { name: 'Events', path: '/', icon: 'calendar-alt' },
                // { name: 'Bingo', path: '/bingo', icon: 'border-all' },
                // { name: 'Visit at IMTS', path: '/info', icon: 'map-marker-alt' },
                { name: 'Home', path: '/', icon: 'home' },
                { name: 'About Chatter', url: 'https://chatter.dev/', img: 'icon-256.png' },
            ]
        }
    },
    methods: {
        handleLink(link) {
            if (link.path) {
                this.setRouterPath(link.path);
            } else if (link.url) {
                window.open(link.url, '_blank');
            }
        },
        setRouterPath(path) {
            if (this.$route.path === path) return;
            this.$router.push(path);
        },
        goToUrl(url) {
            window.open(url, '_blank');
        },
    }

}
</script>

<style scoped>
/* Desktop */
.navbar p {
    text-align:center;
}
@media (min-width: 768px) {
    .navbar {
        position:fixed;
        top: 0;
        left: 0;
        width: 70px;
        display: flex;
        align-items: center;
        background-color: #333;

        justify-content: center;
        flex-direction: column;
        height: 100vh;
        gap: 24px;
        text-align: center;
        z-index: 1;
    }
    .navbar img.navbar-header {
        width: 44px;
        height: 44px;
        position: absolute;
        top: 17px;
    }
    .navbar img.navbar-header:hover {
        cursor: pointer;
        filter: brightness(1.2);
    }
    .navbar > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        color: var(--text-dark);

        padding: 12px;
        gap: 3px;
        flex-grow: 0;
        cursor: pointer;
    }
    .navbar > div.selected, .navbar > div:hover {
        color: var(--text);
    }
    .navbar svg, .navbar img {
        height: 24px;
    }
    .navbar > div img {
        filter: grayscale(100%) invert(1);
        background-color: #fff;
        mix-blend-mode: screen;
    }
    .navbar > div.selected img, .navbar > div.hover img {
        /* brighten */
        filter: grayscale(100%) invert(1) brightness(1.5);
    }
}

/* Mobile */
@media (max-width: 768px) {
    .navbar {
        position: fixed;
        z-index: 1;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-top:1px solid #444;
        background-color: #333;
    }
    
    .navbar img.navbar-header {
        display: none !important;
    }
    .navbar > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 12px 0;
        font-size: 14px;
        gap: 4px;
        color: var(--text-dark);
    }
    .navbar > div.selected {
        color: var(--text);
    }
    .navbar svg, .navbar img {
        height: 20px;
    }
    .navbar > div img {
        filter: grayscale(100%) invert(1);
        background-color: #fff;
        mix-blend-mode: screen;
    }
    .navbar > div.selected img {
        /* brighten */
        filter: grayscale(100%) invert(1) brightness(1.5);
    }
}

</style>